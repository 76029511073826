import { ReactElement } from 'react';

export enum CancellationReasonType {
  NO_ACCESS,
  MAKE_A_CHANGE,
  ADDED_TO_MAIN_INSURANCE,
  MISSING_FEATURE,
  DOES_NOT_MEET_REQUIREMENTS,
  DOES_NOT_MEET_REQUIREMENTS_PRICE,
  DOES_NOT_MEET_REQUIREMENTS_ADD_ONS,
  DOES_NOT_MEET_REQUIREMENTS_NAMED_DRIVER,
  DOES_NOT_MEET_REQUIREMENTS_VEHICLE,
  DOES_NOT_MEET_REQUIREMENTS_MULTICAR,
  DOES_NOT_MEET_REQUIREMENTS_OTHER,
  PASSED_TEST,
  BOOKED_TEST,
  LTN_PASSED_TEST,
  LTN,
  LTN_TRACKING,
  LTN_PASS_PRICE,
  LTN_QUARTERLY_REPRICE,
  LTN_MINIMUM_MILEAGE,
  LTN_EXCESS_TOO_HIGH,
  LTN_OTHER,
  NO_ACCESS_ANOTHER_PROVIDER,
  NO_ACCESS_VEYGO,
  NO_ACCESS_NO_INSURANCE,
  MAKE_A_CHANGE_PAYMENT_DETAILS,
  MAKE_A_CHANGE_PAYMENT_DATE,
  MAKE_A_CHANGE_PAUSE_SUBSCRIPTION,
  MAKE_A_CHANGE_PERSONAL_DETAILS,
  MAKE_A_CHANGE_VEHICLE,
  MAKE_A_CHANGE_OTHER,
  PRICE,
  PRICE_CONTINUE,
  PRICE_ANOTHER_PROVIDER,
  PRICE_SHOP_AROUND,
  PRICE_DONT_KNOW,
}

export type CancelReason = {
  reasonId: CancellationReasonType;
  value: string;
  name: string;
  child?: ReactElement;
};

export const getCancellationReasons = (
  showPassedTest: boolean,
  isSubscription: boolean,
  showSecondaryCancellationReasons: boolean,
  isLearnerPlusNewdriver = false,
): Array<CancelReason> => {
  const cancellationReasons = showSecondaryCancellationReasons
    ? [
        {
          reasonId: CancellationReasonType.NO_ACCESS,
          value: 'Vehicle access',
          name: 'I no longer have access to the vehicle',
        },
        {
          reasonId: CancellationReasonType.MAKE_A_CHANGE,
          value: `${isSubscription ? 'Subscription' : 'Policy'} change`,
          name: `I need to make a change to my ${isSubscription ? 'subscription' : 'policy'}`,
        },
        {
          reasonId: CancellationReasonType.ADDED_TO_MAIN_INSURANCE,
          value: 'I have been added to the main insurance',
          name: 'I have been added to the main insurance',
        },
        {
          reasonId: CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS,
          value: 'Requirements',
          name: "The insurance doesn't meet my requirements",
        },
        {
          reasonId: CancellationReasonType.PRICE,
          value: 'Price',
          name: 'I got a better price with another provider',
        },
      ]
    : [
        {
          reasonId: CancellationReasonType.NO_ACCESS,
          value: 'I no longer have access to the vehicle',
          name: 'I no longer have access to the vehicle',
        },
        {
          reasonId: CancellationReasonType.MAKE_A_CHANGE,
          value: `I need to make a change to my ${isSubscription ? 'subscription' : 'policy'}`,
          name: `I need to make a change to my ${isSubscription ? 'subscription' : 'policy'}`,
        },
        {
          reasonId: CancellationReasonType.ADDED_TO_MAIN_INSURANCE,
          value: 'I have been added to the main insurance',
          name: 'I have been added to the main insurance',
        },
        {
          reasonId: CancellationReasonType.MISSING_FEATURE,
          value: 'You are missing a feature I need',
          name: 'You are missing a feature I need',
        },
        {
          reasonId: CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS,
          value: "The insurance doesn't meet my requirements",
          name: "The insurance doesn't meet my requirements",
        },
      ];

  if (showPassedTest) {
    return [
      {
        reasonId: CancellationReasonType.PASSED_TEST,
        value: 'I have passed my test',
        name: 'I have passed my driving test',
      },
      ...cancellationReasons,
    ];
  }

  if (isLearnerPlusNewdriver) {
    return [
      {
        reasonId: CancellationReasonType.LTN_PASSED_TEST,
        value: 'I have passed my test',
        name: 'I have passed my driving test',
      },
      {
        reasonId: CancellationReasonType.BOOKED_TEST,
        value: 'I have booked my test',
        name: 'I have booked my driving test',
      },
      {
        reasonId: CancellationReasonType.LTN,
        value: "I don't want to stay with Veygo after I pass",
        name: "I don't want to stay with Veygo after I pass",
      },
      ...cancellationReasons,
    ];
  }

  return cancellationReasons;
};

export const getSecondaryCancellationQuestion = (reasonId: CancellationReasonType): string => {
  if (reasonId === CancellationReasonType.NO_ACCESS) {
    return 'Do you need insurance for another vehicle?';
  }
  if (reasonId === CancellationReasonType.MAKE_A_CHANGE) {
    return 'What is the change you need to make?';
  }
  if (reasonId === CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS) {
    return 'What requirements do you have?';
  }
  if (reasonId === CancellationReasonType.PRICE) {
    return 'Would you stay with us if we offer you a discounted price?';
  }
  return '';
};

export const getSecondaryCancellationReasons = (
  cancelReasonId: CancellationReasonType,
): Array<CancelReason> => {
  if (cancelReasonId === CancellationReasonType.NO_ACCESS) {
    return [
      {
        reasonId: CancellationReasonType.NO_ACCESS_ANOTHER_PROVIDER,
        value: 'Use another provider',
        name: "Yes, but I'll use another provider",
      },
      {
        reasonId: CancellationReasonType.NO_ACCESS_VEYGO,
        value: 'Purchase Veygo Policy',
        name: "Yes, I'll buy a new Veygo policy",
      },
      {
        reasonId: CancellationReasonType.NO_ACCESS_NO_INSURANCE,
        value: "Don't need insurance",
        name: "No, I don't need insurance",
      },
    ];
  }
  if (cancelReasonId === CancellationReasonType.MAKE_A_CHANGE) {
    return [
      {
        reasonId: CancellationReasonType.MAKE_A_CHANGE_VEHICLE,
        value: 'Vehicle on policy',
        name: 'Change the vehicle on policy',
      },
      {
        reasonId: CancellationReasonType.MAKE_A_CHANGE_PAYMENT_DETAILS,
        value: 'Payment details',
        name: 'Change my payment details',
      },
      {
        reasonId: CancellationReasonType.MAKE_A_CHANGE_PAYMENT_DATE,
        value: 'Payment date',
        name: 'Change the payment date of my subscription',
      },
      {
        reasonId: CancellationReasonType.MAKE_A_CHANGE_PAUSE_SUBSCRIPTION,
        value: 'Pause subscription',
        name: 'Need to pause my subscription',
      },
      {
        reasonId: CancellationReasonType.MAKE_A_CHANGE_PERSONAL_DETAILS,
        value: 'Change personal details',
        name: 'Change my contact/personal details',
      },
      {
        reasonId: CancellationReasonType.MAKE_A_CHANGE_OTHER,
        value: 'Other',
        name: 'Other',
      },
    ];
  }
  if (cancelReasonId === CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS) {
    return [
      {
        reasonId: CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS_PRICE,
        value: 'Price',
        name: 'I need insurance that meets my budget',
      },
      {
        reasonId: CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS_ADD_ONS,
        value: 'Add ons',
        name:
          "I need add ons that you don't offer (eg. hire car, personal injury cover, motor legal protection)",
      },
      {
        reasonId: CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS_NAMED_DRIVER,
        value: 'Named driver',
        name: 'I want to be able to add drivers to my policy',
      },
      {
        reasonId: CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS_VEHICLE,
        value: 'Change vehicle',
        name: 'I need to be able to change the car on cover',
      },
      {
        reasonId: CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS_MULTICAR,
        value: 'Multiple vehicles',
        name: 'I need to be able to use multiple cars with my policy',
      },
      {
        reasonId: CancellationReasonType.DOES_NOT_MEET_REQUIREMENTS_OTHER,
        value: 'Other',
        name: 'Other',
      },
    ];
  }
  if (cancelReasonId === CancellationReasonType.PRICE) {
    return [
      {
        reasonId: CancellationReasonType.PRICE_CONTINUE,
        value: 'Would continue subscription',
        name: 'Yes, I would continue with my subscription',
      },
      {
        reasonId: CancellationReasonType.PRICE_ANOTHER_PROVIDER,
        value: 'Already purchased alternative insurance',
        name: 'No, I already bought a new policy with another provider',
      },
      {
        reasonId: CancellationReasonType.PRICE_SHOP_AROUND,
        value: 'Want to shop around',
        name: 'No, I want to shop around',
      },
      {
        reasonId: CancellationReasonType.PRICE_DONT_KNOW,
        value: "Don't know",
        name: "I don't know",
      },
    ];
  }
  if (cancelReasonId === CancellationReasonType.LTN) {
    return [
      {
        reasonId: CancellationReasonType.LTN_TRACKING,
        // this space is in the model so will be removed in next migration
        value: " I don't want to track my driving",
        name: "I don't want to track my driving",
      },
      {
        reasonId: CancellationReasonType.LTN_PASS_PRICE,
        value: "I don't understand my pass price",
        name: "I don't understand my pass price",
      },
      {
        reasonId: CancellationReasonType.LTN_QUARTERLY_REPRICE,
        value: "I don't want my price to change every three months",
        name: "I don't want my price to change every three months",
      },
      {
        reasonId: CancellationReasonType.LTN_MINIMUM_MILEAGE,
        value: "I won't meet the minimum mileage required",
        name: "I won't meet the minimum mileage required",
      },
      {
        reasonId: CancellationReasonType.LTN_EXCESS_TOO_HIGH,
        value: 'The excess is too high',
        name: 'The excess is too high',
      },
      {
        reasonId: CancellationReasonType.LTN_OTHER,
        value: 'Other',
        name: 'Other',
      },
    ];
  }
  return [];
};

export const createReasonFromMainAndSecondaryReasons = (
  mainReason: string,
  secondaryReason: string,
) => {
  return secondaryReason ? `${mainReason} - ${secondaryReason}` : mainReason;
};
