import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { TextArea } from '@rentecarlo/component-library';

const CommentPrompt = styled.div`
  margin: 8px;
  font-size: 16px;
  font-weight: 700;
  color: #364344;
`;

interface Props {
  id: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  title?: string;
}

const CustomerComment: React.FC<Props> = ({ id, name, placeholder, value, onChange, title }) => {
  return (
    <div>
      {title && <CommentPrompt>{title}</CommentPrompt>}
      <TextArea
        maxLength={255}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default CustomerComment;
